<template>
  <div>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <el-form :inline="true" :model="formInline" class="demo-form-inline" ref="searchform">
            <el-form-item label="设备IMEI" prop="imei">
              <el-input placeholder="设备IMEI" v-model="formInline.imei_keyword"></el-input>
            </el-form-item>
            <el-form-item :label="$t('index.device_type')" prop="type">
              <el-select v-model="formInline.type" :placeholder="$t('index.device_type')" clearable>
                <el-option
                    v-for="item in deviceType"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('device.vesting')" prop="client_id">
              <el-cascader
                  :options="clientData"
                  :placeholder="$t('device.select_customer')"
                  v-model="formInline.client_id"
              ></el-cascader>
            </el-form-item>
            <el-form-item>
              <el-button @click="searchBtn" type="primary">{{ $t('device.inquire') }}</el-button>
              <el-button @click="resetBtn" type="primary">{{ $t('device.reset') }}</el-button>
              <el-button @click="exportBtn" type="primary" :loading="loading">{{ $t('device.export') }}</el-button>
              <!-- </el-link> -->
            </el-form-item>
          </el-form>
        </div>
      </template>
      <el-table :data="listData" border stripe style="width: 100%" @sort-change="sortChange">
        <!--        <el-table-column type="selection" width="55"></el-table-column>-->
        <el-table-column :label="$t('index.device')" prop="imei" width="180"></el-table-column>
        <el-table-column :label="$t('index.device_name')" prop="name" sortable="custom" width="200"></el-table-column>
        <!--        <el-table-column :label="$t('index.device_type')" prop="type"></el-table-column>-->
        <el-table-column :label="$t('index.online_status')" prop="is_online" width="200"></el-table-column>
        <el-table-column :label="$t('index.battery_level')" prop="volt" sortable="custom" width="200"></el-table-column>
        <el-table-column :label="$t('detail.temp')" prop="temp" width="200"></el-table-column>
        <el-table-column :label="$t('detail.heart')" prop="heart_rate" width="200"></el-table-column>
        <el-table-column :label="$t('detail.blood')" width="200">
          <template #default="scope">{{ scope.row.blood_diastolic }} / {{ scope.row.blood_systolic }}</template>
        </el-table-column>
        <el-table-column :label="$t('device.last_comm_time')" prop="update_at" sortable="custom" width="200"></el-table-column>
        <el-table-column :label="$t('device.operating')" width="320">
          <template #default="scope">
            <el-button @click="handleDetail(scope.row.imei)" size="small"
                       v-if="$store.state.pageAction.indexOf('detail.detail')> -1">{{ $t('detail.detail') }}
            </el-button>
            <el-button @click="healthEdit(scope.row.imei)" size="small"
                       v-if="$store.state.pageAction.indexOf('health.title')> -1">{{ $t('health.title') }}
            </el-button>
            <el-button @click="locationEdit(scope.row.imei)" size="small"
                       v-if="$store.state.pageAction.indexOf('index.positioning')> -1">{{ $t('index.positioning') }}
            </el-button>
            <el-button @click="trackEdit(scope.row.imei)" size="small"
                       v-if="$store.state.pageAction.indexOf('detail.trajectory')> -1">{{ $t('detail.trajectory') }}
            </el-button>
            <el-button @click="fenceEdit(scope.row)" size="small"
                       v-if="$store.state.pageAction.indexOf('fence.title')> -1">{{ $t('fence.title') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :current-page="formInline.page"
          :page-size="formInline.size"
          :page-sizes="[10, 20, 50]"
          :total="listCount"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </el-card>
  </div>
</template>
<script>
import data from "@/model/data";
import device from "@/model/device";
import tool from "../../utils/tool";
import clients from "@/model/client";

export default {
  name: "dataHome",
  data() {
    return {
      deviceType: [],
      currentPage: 1,
      clientData: [],
      formInline: {
        imei: "",
        imei_keyword: "",
        client_id: "",
        type: "",
        online: "",
        page: 1,
        size: 10,
        order: {}
      },
      loading: false,
      listData: [],
      listCount: 0,
      orderParam: {
        prop: "",
        order: ""
      }
    };
  },
  async mounted() {
    if (!tool.isEmpty(this.$route.query.imei)) {
      this.formInline.imei_keyword = this.$route.query.imei
    }
    await this.equipList();
    await this.clientList();
    await this.deviceTypeArr();
  },
  methods: {
    async deviceTypeArr() {
      const ret = await device.config();
      if (ret.code === 200) {
        this.deviceType = ret.data;
      }
    },
    async equipList() {
      this.formInline.order = this.orderParam
      const ret = await data.list(this.formInline);
      if (ret.code === 200) {
        this.listData = ret.data.list;
        this.listCount = ret.data.count;
      }
    },
    sortChange(column) {
      if (column.prop === 'name') {
        this.orderParam.prop = 'user.' + column.prop;
      } else {
        this.orderParam.prop = 'device.' + column.prop;
      }
      this.orderParam.order = column.order;
      this.equipList();
    },
    async clientList() {
      const ret = await clients.list();
      if (ret.code === 200) {
        this.clientData = this.handleClientTree(ret.data)
      }
    },
    handleClientTree(data) {
      if (data.length === 0) {
        return []
      }
      let options = []
      data.forEach(item => {
        options.push({
          value: item.id,
          label: item.name
        })
        if (item.children.length > 0) {
          options.push({
            value: item.id,
            label: item.name,
            children: this.handleClientTree(item.children)
          })
        }
      })
      return options
    },
    handleDetail(imei) {
      this.$router.push({
        path: "/device/detail",
        query: {
          imei: imei
        }
      });
    },
    healthEdit(imei) {
      this.$router.push({
        path: "/data/health",
        query: {
          imei: imei
        }
      });
    },
    locationEdit(imei) {
      this.$router.push({
        path: "/data/location",
        query: {
          imei
        }
      });
    },
    trackEdit(imei) {
      this.$router.push({
        path: "/data/track",
        query: {
          imei: imei
        }
      });
    },
    fenceEdit(data) {
      tool.saveLocalStorage(
          "fence_device",
          JSON.stringify({
            imei: data.imei,
            name: data.name,
            online: data.is_online,
            type: data.type
          })
      );
      this.$router.push({
        path: "/data/fence",
        query: {
          imei: data.imei
        }
      });
    },
    searchBtn() {
      this.equipList();
    },
    resetBtn() {
      this.formInline.imei_keyword = "";
      this.formInline.type = "";
      this.equipList();
    },
    async exportBtn() {
      this.loading = true;
      const ret = await data.export({
        imei: this.formInline.imei_keyword
      });
      if (ret.code === 200) {
        window.open(ret.data);
        this.loading = false;
      }
    },
    handleSizeChange(val) {
      this.formInline.size = val;
      this.equipList();
    },
    handleCurrentChange(val) {
      this.formInline.page = val;
      this.equipList();
    }
  }
};
</script>
<style scoped>
.demo-form-inline {
  margin-top: 20px;
}

.el-pagination {
  margin: 30px 0px;
  float: right;
}
</style>

